import React from "react"
import { Row, Col, Container, Button } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
  <Layout pageInfo={{ pageName: "contact" }}>
    <SEO title="Plan Your Trip" />

    <Container className="main-container">
      <Container className="main-container">
        <h1 className="about-header mt-3">Contact Us</h1>
        <Row>
          <Col className="content">
            Email or call us to get started on planning your mountain getaway or
            event. You work hard so you can enjoy your time in the mountains.
            Let us customize every aspect of your trip to Telluride.
          </Col>
        </Row>
        <Row className="about-header mb-3 mt-3">
          <Col>Unwind and let the relaxing begin!</Col>
        </Row>
      </Container>

      <form
        className="form-container"
        name="contact"
        method="post"
        action="/success"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <div className="mb-2 content">
          <p className="h5 content">Click to call</p>
          <a href="tel:9703699219">
            <Button className="fancy-button" variant="success">
              970-369-9219
            </Button>
          </a>
        </div>
        or
        <p className="h5 mb-4 content">
          <strong>Send a message</strong>
        </p>
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <div className="field half first">
          <input
            className="form-control mb-4"
            placeholder="Name"
            type="text"
            name="name"
            id="name"
          />
        </div>
        <div className="field half">
          <input
            className="form-control mb-4"
            type="email"
            name="email"
            id="email"
            placeholder="E-mail"
          />
        </div>
        <div className="field">
          <textarea
            className="form-control rounded-0"
            name="message"
            id="message"
            rows="3"
            placeholder="Message"
          />
        </div>
        <br />
        <div className="actions">
          <Button
            className="btn btn-block fancy-button special"
            type="submit"
            variant="success"
          >
            Send
          </Button>
        </div>
      </form>
    </Container>
  </Layout>
)

export default ContactPage
